.dataElement {
  display: inline-block;
  padding: 0 5px;
  margin: 5px 10px 5px 0;
  border: 1px solid black;
}

.dataElement > div {
  display: inline-block;
  margin: 0;
}

.dataElement > h6 {
  border-bottom: 1px solid gray;
}
