.patientState {
  margin-bottom: 20px;
}

.patientState > div {
  display: inline-block;
  padding: 0 5px;
  margin: 5px 10px 5px 0;
  border: 1px solid black;
}

.dataElement > div {
  display: inline-block;
  padding: 0 5px;
  margin: 5px 10px 5px 0;
  font-weight: bold;
  font-size: 30px;
}


.patientState h6 {
  border-bottom: 1px solid gray;
}
