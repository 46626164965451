
.chartDataSelection {
}

.chartDataSelection > h5 {
  margin: 5px 10px;
  width: 250px;
}

.chartDataSelection > .dataBlock {
  padding: 5px;
  margin: 5px 10px;
}

.chartDataSelection > .dataBlock > Select {
  height: 30px;
  padding: 0 5px;
  font-size: 15px;
  float: right;
  width: 100px;
  display: inline-block;
}

.timeRangeToggler {

}

.timeRangeToggler > button {
  width: 80px;
  padding: 0;
  margin-bottom: 8px !important;
}

.timeRangeToggler > .fromToDate {
  display: inline-block;
}

.timeRangeToggler > input[type=checkbox] {
  position: relative;
  top: 6px;
}

.timeRangeToggler > .fromToDate > input[type=date] {
  display: inline-block;
  width: 150px;
  height: 30px;
  margin: 0 10px;
}

.togglerLabel {
  width: 80px;
}

.switch {
  top: 8px;
  margin-right: 10px;
  margin-left: 10px;
}

.rangeControl {
  height: 240px;
}

.legend {
  border: 1px solid gray;
  padding: 5px;
  margin: 5px 0;
  height: 80px;
}

.legend > h4 {
  font-size: 16px;
  border-bottom: 1px dotted gray;
}

.legend > .stats {
  font-size: 14px;
}

.legend > .stats_minmax {
  font-size: 14px;
  display: inline-block;
}

.legend > .stats_value {
  font-size: 30px;
  display: inline-block;
  padding: 0;
  margin-right: 5px;
  vertical-align: top;
  float: right;
  top: -6px;
  position: relative;
}

.spinnerSpacer {
  align-content: center;
  width: 75%;
  height: 800px;
}

.markerStyleTime {
  background-color: rgba(255, 255, 255, 0.8);
  color: black;
  padding: 5px;
  margin: 5px;
  border-bottom: 1px solid grey;
  width: 200px;
}

.markerStyleValues {
  background-color: rgba(255, 255, 255, 0.8);
  color: black;
  padding: 5px;
  margin: 5px;
  width: 200px;
}

.markerStyleValues th {
  padding: 0 5px;
  width: 160px;
}
.markerStyleValues td {
  text-align: right;
  padding-right: 5px;
}
