.dataBlock {
  padding: 5px;
  margin: 5px 10px;
}

.dataBlock > Select {
  height: 30px;
  padding: 0 5px;
  font-size: 15px;
  width: 100px;
}
