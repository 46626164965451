.App {
  text-align: left;
}

.App-logo {
  height: 8vmin;
  pointer-events: none;
  float: left;
}

.App-header {
  padding: 2vh;
  min-height: 8vh;
  /* display: flex; */
  flex-direction: column;
  align-items: flex-start;
  justify-content: left;
  font-size: calc(10px + 2vmin);
  /* background-color: #0c1d37; */
}

.App-link {
  color: rgb(112, 76, 182);
}

@keyframes App-logo-float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px)
  }
  100% {
    transform: translateY(0px)
  }
}

.nav-item a.nav-link {
  border: 1px solid gray;
  border-radius: 5px;
  margin: 5px;
}

.nav-item a:hover {
  background-color: lightgray;
}

a.dropdown-item {
  border: none;
  border-radius: 0;
}
