.timeRangeToggler {

}

.timeRangeToggler > button {
    width: 80px;
    padding: 0;
    margin-bottom: 8px !important;
}

.timeRangeToggler > .fromToDate {
    display: inline-block;
}

.timeRangeToggler > input[type=checkbox] {
    position: relative;
    top: 6px;
}

.timeRangeToggler > .fromDate {
    display: inline-block;
    width: 150px;
    height: 30px;
    margin: 0 10px;
}

.timeRangeToggler > .direction {
    width: 100px;
    display: inline-block;
    margin: 0 10px;
}

.rangeControl {
    height: 200px;
}

.rangeControlX {
    height: 40px;
    text-align: center;
}

.rangeControlX > input {
    display: inline-block;
    width: 50px;
    margin: 0 10px;
}

.legend {
    border: 1px solid gray;
    padding: 5px;
    margin: 5px 0;
    height: 80px;
}

.legend > h4 {
    font-size: 16px;
    border-bottom: 1px dotted gray;
}

.legend > .stats {
    font-size: 14px;
}

.legend > .stats_minmax {
    font-size: 14px;
    display: inline-block;
}

.legend > .stats_value {
    font-size: 30px;
    display: inline-block;
    padding: 0;
    margin-right: 5px;
    vertical-align: top;
    float: right;
    top: -6px;
    position: relative;
}
