.column {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 16px;
}

.row {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 16px;
}

.alertHead {
  width: 25px;
}

.button {
  padding-left: 12px;
  padding-right: 12px;
  outline: none;
  border: 1px solid black;
  padding-bottom: 4px;
  background-color: white;
  border-radius: 5px;
  color: black;
  cursor: pointer;
}
